import {Route, Routes} from 'react-router-dom';
import Navbar from './Navbar';
import PrivateRoute from 'components/PrivateRoute';

import './styles.css';
import React from "react";

const Admin = () => {
  return (
    <div className="admin-container">
      <Navbar />
      <div className="admin-content">
          <Routes>
              <Route path="/admin/products}" element={<PrivateRoute roles={['ROLE_ADMIN', 'ROLE_USER']} />}>
                  <Route index element={<h1>Dashboard Crud</h1>}/>
              </Route>
          </Routes>
      </div>
    </div>
  );
};

export default Admin;
