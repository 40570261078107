import './styles.css';

import { Collection } from 'types/collection';
import {Link} from "react-router-dom";
import { TbAntennaBars5 } from "react-icons/tb";

type Props = {
    collection: Collection;
}

const CollectionCard = ( { collection } : Props) => {

    return (
        <div className="card mb-3" >
            <div className="card-header card-title">
                {collection.nomeColecao}
            </div>
            <ul className="list-group card-font">
                {collection.dashboards.map(dashboard => (
                    <Link to={`/dashboards/${collection.id}/${dashboard.database_id}`}>
                        <li className="list-group-item" key={dashboard.database_id}>
                            <TbAntennaBars5 size={18} style={{marginRight: 4}} /> {dashboard.name}
                        </li>
                    </Link>
                ))}
            </ul>
        </div>
    );
}

export default CollectionCard;