import {Navigate, Route, useLocation, Outlet} from 'react-router-dom';
import {hasAnyRoles, isAuthenticated, Role} from 'util/auth';
import React from "react";

type Props = {
    roles?: Role[];
};

const PrivateRoute = ({roles = []}: Props) => {
    const location = useLocation();
    if (!isAuthenticated()) {
        return (
            <Navigate
                to="/admin/auth/login"
                state={{from: location}}
                replace
            />
        );
    }
    if (!hasAnyRoles(roles)) {
        return <Navigate to={"admin"} replace/>;
    }

    return <Outlet />;
};

export default PrivateRoute;
