// import AuthImage from '../../../assets/images/auth-image.svg';
import {Route, Routes} from 'react-router-dom';
import Login from './Login';

import './styles.css';

const Auth = () => {

    return (
        <div className="auth-container fullscreen-background">
            <div className="auth-form-container">
                <Routes>
                    <Route path="login" element={<Login/>}/>
                    <Route path="signup" element={<h1>Card de Signup</h1>}/>
                    <Route path="recover" element={<h1>Card de Recover</h1>}/>
                </Routes>
            </div>
        </div>
    );
}

export default Auth;