import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { requestBackend } from 'util/requests';
import { AxiosRequestConfig } from 'axios';
import CardLoader from './CardLoader';


import './styles.css';
import {Collection} from "../../types/collection";
import CollectionCard from "components/CollectionCard";
import Navbar from "../../components/Navbar";

const Catalog = () => {
  const [page, setPage] = useState<Collection[]>();
  const [isLoading, setIsLoading] = useState(false);
  const getCollections = () => {
    const params: AxiosRequestConfig = {
      method: 'GET',
      url: 'collection/',
      withCredentials: true,
    };
      setIsLoading(true);
      requestBackend(params)
        .then((response) => {
          setPage(response.data);
        })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
      getCollections();
  }, []);

  return (
      <>
        <Navbar />

    <div className="container-fluid p-4 catalog-container">
      <div className="row">
        {isLoading ? (
            <div className="alert alert-danger d-flex align-items-center" role="alert">
                  <span className="spinner-border spinner-border-sm me-2"
                        role="status"></span> Aguarde, estamos carregando sua biblioteca de dashboards !
            </div>
        ) : (
          page?.map((collection) => (
            <div className="col-sm-6 col-lg-4 col-xl-3" key={collection.id}>
                <CollectionCard collection={collection} />
            </div>
          ))
        )}
      </div>
    </div>
      </>
  );
};

export default Catalog;
