import {Link, useParams} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { requestBackend } from 'util/requests';
import { AxiosRequestConfig } from 'axios';
import CardLoader from './CardLoader';


import './styles.css';
import {Collection} from "../../types/collection";
import CollectionCard from "components/CollectionCard";
import Navbar from "../../components/Navbar";
import {DashboardURL} from "../../types/dashboardURL";

const Dashboard = () => {
  const { collectionId, dashboardId } = useParams();
  const [page, setPage] = useState<DashboardURL>();
  const [isLoading, setIsLoading] = useState(false);
  const getDashboard = () => {
    const params: AxiosRequestConfig = {
      method: 'GET',
      url: `collection/${collectionId}/dashboard/${dashboardId}`,
      withCredentials: true,
    };
      setIsLoading(true);
      requestBackend(params)
        .then((response) => {
          setPage(response.data);
        })
      .finally(() => {
      });
  }

  useEffect(() => {
    getDashboard();
  }, []);

  return (
      <>
        <Navbar/>

          {isLoading && (
              <div className="container-fluid p-4 catalog-container">
              <div className="alert alert-danger d-flex align-items-center" role="alert">
                  <span className="spinner-border spinner-border-sm me-2"
                        role="status"></span> Aguarde, estamos montando seu dashboard !
              </div>
              </div>
          )}
          <iframe
              src={page?.dashboard_url}
              allowTransparency={true}
              className="iframe-fullscreen dashboard-container"
              onLoad={() => setIsLoading(false)}
          />
      </>
  );
};

export default Dashboard;
