import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import React from "react";
import Admin from "./pages/Admin";
import Auth from "./pages/Admin/Auth";
import Catalog from "./pages/Catalog";
import Dashboard from "./pages/Dashboard";

const AppRoutes = () => (
  <BrowserRouter>
      <Routes>
          {/*<Route path="/" element={<Home />} />*/}
          <Route path="/dashboards" element={<Catalog />} />
          <Route path="/dashboards/:collectionId/:dashboardId" element={<Dashboard />} />
          {/*<Route path="/products/:productId" element={<ProductDetails />} />*/}
          <Route path="/admin/auth/*" element={<Auth />} />
          <Route path="/admin" element={<Admin />} />

          {/* Redirects */}
          <Route path="/" element={<Navigate replace to="/admin/auth/login" />} />
          {/*<Route path="/admin" element={<Navigate replace to="/admin/products" />} />*/}
      </Routes>
  </BrowserRouter>
);

export default AppRoutes;
